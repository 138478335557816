"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")["default"];
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread2"));
var _defaultAppData = _interopRequireDefault(require("default-app/dist/common/defaultAppData"));
var _default = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _defaultAppData["default"]), {}, {
  "compositions": [{
    data: [{
      width: 1,
      height: 1,
      material: {
        color: "#2c1367",
        opacity: 1
      },
      position: {
        x: 0,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#2c1367",
        opacity: 1
      },
      position: {
        x: 1,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#2b1468",
        opacity: 1
      },
      position: {
        x: 2,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#29166a",
        opacity: 1
      },
      position: {
        x: 3,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#251b6f",
        opacity: 1
      },
      position: {
        x: 4,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#211f73",
        opacity: 1
      },
      position: {
        x: 5,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#1f2175",
        opacity: 1
      },
      position: {
        x: 6,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#1f2175",
        opacity: 1
      },
      position: {
        x: 7,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#607d8b",
        opacity: 1
      },
      position: {
        x: 0,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ff8a65",
        opacity: 1
      },
      position: {
        x: 2,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#90a4ae",
        opacity: 1
      },
      position: {
        x: 3,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ff8a65",
        opacity: 1
      },
      position: {
        x: 4,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#90a4ae",
        opacity: 1
      },
      position: {
        x: 5,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#90a4ae",
        opacity: 1
      },
      position: {
        x: 6,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#2c1349",
        opacity: 1
      },
      position: {
        x: 0,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#2c1349",
        opacity: 1
      },
      position: {
        x: 1,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#29164f",
        opacity: 1
      },
      position: {
        x: 2,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#251957",
        opacity: 1
      },
      position: {
        x: 3,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#221c5e",
        opacity: 1
      },
      position: {
        x: 4,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#211d64",
        opacity: 1
      },
      position: {
        x: 5,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#211e69",
        opacity: 1
      },
      position: {
        x: 6,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#202070",
        opacity: 1
      },
      position: {
        x: 7,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#607d8b",
        opacity: 1
      },
      position: {
        x: 0,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ff8a65",
        opacity: 1
      },
      position: {
        x: 2,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#90a4ae",
        opacity: 1
      },
      position: {
        x: 3,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ff8a65",
        opacity: 1
      },
      position: {
        x: 5,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#90a4ae",
        opacity: 1
      },
      position: {
        x: 6,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#90a4ae",
        opacity: 1
      },
      position: {
        x: 7,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#3a1437",
        opacity: 1
      },
      position: {
        x: 0,
        y: 2
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#3a1437",
        opacity: 1
      },
      position: {
        x: 1,
        y: 2
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#36163a",
        opacity: 1
      },
      position: {
        x: 2,
        y: 2
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#2f183e",
        opacity: 1
      },
      position: {
        x: 3,
        y: 2
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#2b1a41",
        opacity: 1
      },
      position: {
        x: 4,
        y: 2
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#2a1c3f",
        opacity: 1
      },
      position: {
        x: 5,
        y: 2
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#261b3b",
        opacity: 1
      },
      position: {
        x: 6,
        y: 2
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#291b39",
        opacity: 1
      },
      position: {
        x: 7,
        y: 2
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#607d8b",
        opacity: 1
      },
      position: {
        x: 0,
        y: 2
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ff8a65",
        opacity: 1
      },
      position: {
        x: 2,
        y: 2
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#90a4ae",
        opacity: 1
      },
      position: {
        x: 3,
        y: 2
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#572039",
        opacity: 1
      },
      position: {
        x: 0,
        y: 3
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#572039",
        opacity: 1
      },
      position: {
        x: 1,
        y: 3
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#482139",
        opacity: 1
      },
      position: {
        x: 2,
        y: 3
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#49243b",
        opacity: 1
      },
      position: {
        x: 3,
        y: 3
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#4a273d",
        opacity: 1
      },
      position: {
        x: 4,
        y: 3
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#4b2a3f",
        opacity: 1
      },
      position: {
        x: 5,
        y: 3
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#47273a",
        opacity: 1
      },
      position: {
        x: 6,
        y: 3
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#4c2536",
        opacity: 1
      },
      position: {
        x: 7,
        y: 3
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#607d8b",
        opacity: 1
      },
      position: {
        x: 0,
        y: 3
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ffa000",
        opacity: 1
      },
      position: {
        x: 3,
        y: 3
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#90a4ae",
        opacity: 1
      },
      position: {
        x: 4,
        y: 3
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ffa000",
        opacity: 1
      },
      position: {
        x: 6,
        y: 3
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#90a4ae",
        opacity: 1
      },
      position: {
        x: 7,
        y: 3
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#7f324e",
        opacity: 1
      },
      position: {
        x: 0,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#7f324e",
        opacity: 1
      },
      position: {
        x: 1,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#7f334d",
        opacity: 1
      },
      position: {
        x: 2,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#7f344c",
        opacity: 1
      },
      position: {
        x: 3,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#80354b",
        opacity: 1
      },
      position: {
        x: 4,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#80354a",
        opacity: 1
      },
      position: {
        x: 5,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#803649",
        opacity: 1
      },
      position: {
        x: 6,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#803748",
        opacity: 1
      },
      position: {
        x: 7,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#607d8b",
        opacity: 1
      },
      position: {
        x: 0,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ffc107",
        opacity: 1
      },
      position: {
        x: 2,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#90a4ae",
        opacity: 1
      },
      position: {
        x: 3,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#90a4ae",
        opacity: 1
      },
      position: {
        x: 4,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ffa000",
        opacity: 1
      },
      position: {
        x: 5,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#90a4ae",
        opacity: 1
      },
      position: {
        x: 6,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#b04d6f",
        opacity: 1
      },
      position: {
        x: 0,
        y: 5
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#b04d6f",
        opacity: 1
      },
      position: {
        x: 1,
        y: 5
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#b04d6f",
        opacity: 1
      },
      position: {
        x: 2,
        y: 5
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#a94d6c",
        opacity: 1
      },
      position: {
        x: 3,
        y: 5
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ac506c",
        opacity: 1
      },
      position: {
        x: 4,
        y: 5
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ae516b",
        opacity: 1
      },
      position: {
        x: 5,
        y: 5
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#af516b",
        opacity: 1
      },
      position: {
        x: 6,
        y: 5
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#b0516b",
        opacity: 1
      },
      position: {
        x: 7,
        y: 5
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#607d8b",
        opacity: 1
      },
      position: {
        x: 0,
        y: 5
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ffc107",
        opacity: 1
      },
      position: {
        x: 4,
        y: 5
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#90a4ae",
        opacity: 1
      },
      position: {
        x: 5,
        y: 5
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#90a4ae",
        opacity: 1
      },
      position: {
        x: 6,
        y: 5
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#e36d96",
        opacity: 1
      },
      position: {
        x: 0,
        y: 6
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#e36d96",
        opacity: 1
      },
      position: {
        x: 1,
        y: 6
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#e36d96",
        opacity: 1
      },
      position: {
        x: 2,
        y: 6
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#e36e95",
        opacity: 1
      },
      position: {
        x: 3,
        y: 6
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#e36e95",
        opacity: 1
      },
      position: {
        x: 4,
        y: 6
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#e36f95",
        opacity: 1
      },
      position: {
        x: 5,
        y: 6
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#e36e94",
        opacity: 1
      },
      position: {
        x: 6,
        y: 6
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#e36f95",
        opacity: 1
      },
      position: {
        x: 7,
        y: 6
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#607d8b",
        opacity: 1
      },
      position: {
        x: 0,
        y: 6
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ffcdd2",
        opacity: 1
      },
      position: {
        x: 2,
        y: 6
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#90a4ae",
        opacity: 1
      },
      position: {
        x: 3,
        y: 6
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ff80ab",
        opacity: 1
      },
      position: {
        x: 0,
        y: 7
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ff80ab",
        opacity: 1
      },
      position: {
        x: 1,
        y: 7
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ff80ab",
        opacity: 1
      },
      position: {
        x: 2,
        y: 7
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ff80ab",
        opacity: 1
      },
      position: {
        x: 3,
        y: 7
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ff80ab",
        opacity: 1
      },
      position: {
        x: 4,
        y: 7
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ff80ab",
        opacity: 1
      },
      position: {
        x: 5,
        y: 7
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ff80ab",
        opacity: 1
      },
      position: {
        x: 6,
        y: 7
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ff80ab",
        opacity: 1
      },
      position: {
        x: 7,
        y: 7
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#607d8b",
        opacity: 1
      },
      position: {
        x: 0,
        y: 7
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ffcdd2",
        opacity: 1
      },
      position: {
        x: 2,
        y: 7
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ffcdd2",
        opacity: 1
      },
      position: {
        x: 3,
        y: 7
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#90a4ae",
        opacity: 1
      },
      position: {
        x: 4,
        y: 7
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ffcdd2",
        opacity: 1
      },
      position: {
        x: 5,
        y: 7
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#90a4ae",
        opacity: 1
      },
      position: {
        x: 6,
        y: 7
      }
    }],
    background: "rgb(225 225 225)",
    animation: ["enableFirstAnimation", "ArrowRight", "ArrowLeft", "ArrowDown", "D"]
  }, {
    data: [{
      width: 1,
      height: 1,
      material: {
        color: "#1e88e5",
        opacity: 1
      },
      position: {
        x: 1,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#1e88e5",
        opacity: 1
      },
      position: {
        x: 2,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#1e88e5",
        opacity: 1
      },
      position: {
        x: 3,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#1e88e5",
        opacity: 1
      },
      position: {
        x: 4,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#1e88e5",
        opacity: 1
      },
      position: {
        x: 5,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#1e88e5",
        opacity: 1
      },
      position: {
        x: 6,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#1e88e5",
        opacity: 1
      },
      position: {
        x: 7,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 0,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ff9800",
        opacity: 1
      },
      position: {
        x: 2,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 3,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ff6e40",
        opacity: 1
      },
      position: {
        x: 4,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 5,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 6,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#2196f3",
        opacity: 1
      },
      position: {
        x: 0,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#2196f3",
        opacity: 1
      },
      position: {
        x: 1,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#2196f3",
        opacity: 1
      },
      position: {
        x: 2,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#2196f3",
        opacity: 1
      },
      position: {
        x: 3,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#2196f3",
        opacity: 1
      },
      position: {
        x: 4,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#2196f3",
        opacity: 1
      },
      position: {
        x: 5,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#2196f3",
        opacity: 1
      },
      position: {
        x: 6,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#2196f3",
        opacity: 1
      },
      position: {
        x: 7,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 0,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ff9800",
        opacity: 1
      },
      position: {
        x: 2,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 3,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ff6e40",
        opacity: 1
      },
      position: {
        x: 5,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 6,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 7,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#42a5f5",
        opacity: 1
      },
      position: {
        x: 0,
        y: 2
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#42a5f5",
        opacity: 1
      },
      position: {
        x: 1,
        y: 2
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#42a5f5",
        opacity: 1
      },
      position: {
        x: 2,
        y: 2
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#42a5f5",
        opacity: 1
      },
      position: {
        x: 3,
        y: 2
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#42a5f5",
        opacity: 1
      },
      position: {
        x: 4,
        y: 2
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#42a5f5",
        opacity: 1
      },
      position: {
        x: 5,
        y: 2
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#42a5f5",
        opacity: 1
      },
      position: {
        x: 6,
        y: 2
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#42a5f5",
        opacity: 1
      },
      position: {
        x: 7,
        y: 2
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 0,
        y: 2
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ff9800",
        opacity: 1
      },
      position: {
        x: 2,
        y: 2
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 3,
        y: 2
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#64b5f6",
        opacity: 1
      },
      position: {
        x: 0,
        y: 3
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#64b5f6",
        opacity: 1
      },
      position: {
        x: 1,
        y: 3
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#64b5f6",
        opacity: 1
      },
      position: {
        x: 2,
        y: 3
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#64b5f6",
        opacity: 1
      },
      position: {
        x: 3,
        y: 3
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#64b5f6",
        opacity: 1
      },
      position: {
        x: 4,
        y: 3
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#64b5f6",
        opacity: 1
      },
      position: {
        x: 5,
        y: 3
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#64b5f6",
        opacity: 1
      },
      position: {
        x: 6,
        y: 3
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#64b5f6",
        opacity: 1
      },
      position: {
        x: 7,
        y: 3
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 0,
        y: 3
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ce93d8",
        opacity: 1
      },
      position: {
        x: 3,
        y: 3
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 4,
        y: 3
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#fb6e40",
        opacity: 1
      },
      position: {
        x: 6,
        y: 3
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 7,
        y: 3
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ff5252",
        opacity: 1
      },
      position: {
        x: 0,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ff5252",
        opacity: 1
      },
      position: {
        x: 1,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ff5252",
        opacity: 1
      },
      position: {
        x: 2,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ff5252",
        opacity: 1
      },
      position: {
        x: 3,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ff5252",
        opacity: 1
      },
      position: {
        x: 4,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ff5252",
        opacity: 1
      },
      position: {
        x: 5,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ff5252",
        opacity: 1
      },
      position: {
        x: 6,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ff5252",
        opacity: 1
      },
      position: {
        x: 7,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 0,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ce93d8",
        opacity: 1
      },
      position: {
        x: 2,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 3,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 4,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ff8a80",
        opacity: 1
      },
      position: {
        x: 5,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 6,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ef5350",
        opacity: 1
      },
      position: {
        x: 1,
        y: 5
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ef5350",
        opacity: 1
      },
      position: {
        x: 2,
        y: 5
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ef5350",
        opacity: 1
      },
      position: {
        x: 3,
        y: 5
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ef5350",
        opacity: 1
      },
      position: {
        x: 4,
        y: 5
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ef5350",
        opacity: 1
      },
      position: {
        x: 5,
        y: 5
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ef5350",
        opacity: 1
      },
      position: {
        x: 6,
        y: 5
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ef5350",
        opacity: 1
      },
      position: {
        x: 7,
        y: 5
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 0,
        y: 5
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ff8a80",
        opacity: 1
      },
      position: {
        x: 4,
        y: 5
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 5,
        y: 5
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 6,
        y: 5
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#d32f2f",
        opacity: 1
      },
      position: {
        x: 0,
        y: 6
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#d32f2f",
        opacity: 1
      },
      position: {
        x: 1,
        y: 6
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#d32f2f",
        opacity: 1
      },
      position: {
        x: 2,
        y: 6
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#d32f2f",
        opacity: 1
      },
      position: {
        x: 3,
        y: 6
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#d32f2f",
        opacity: 1
      },
      position: {
        x: 4,
        y: 6
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#d32f2f",
        opacity: 1
      },
      position: {
        x: 5,
        y: 6
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#d32f2f",
        opacity: 1
      },
      position: {
        x: 6,
        y: 6
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#d32f2f",
        opacity: 1
      },
      position: {
        x: 7,
        y: 6
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 0,
        y: 6
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ff8a80",
        opacity: 1
      },
      position: {
        x: 2,
        y: 6
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 3,
        y: 6
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#b71c1c",
        opacity: 1
      },
      position: {
        x: 0,
        y: 7
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#b71c1c",
        opacity: 1
      },
      position: {
        x: 1,
        y: 7
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#b71c1c",
        opacity: 1
      },
      position: {
        x: 2,
        y: 7
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#b71c1c",
        opacity: 1
      },
      position: {
        x: 3,
        y: 7
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#b71c1c",
        opacity: 1
      },
      position: {
        x: 4,
        y: 7
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#b71c1c",
        opacity: 1
      },
      position: {
        x: 5,
        y: 7
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#b71c1c",
        opacity: 1
      },
      position: {
        x: 6,
        y: 7
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#b71c1c",
        opacity: 1
      },
      position: {
        x: 7,
        y: 7
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 0,
        y: 7
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ff8a80",
        opacity: 1
      },
      position: {
        x: 2,
        y: 7
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ff8a80",
        opacity: 1
      },
      position: {
        x: 3,
        y: 7
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 4,
        y: 7
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ff5252",
        opacity: 1
      },
      position: {
        x: 5,
        y: 7
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 6,
        y: 7
      }
    }],
    background: "rgb(225 225 225)",
    animation: ["enableFirstAnimation", "ArrowRight", "ArrowLeft", "ArrowDown", "D"]
  }, {
    data: [{
      width: 1,
      height: 1,
      material: {
        color: "#301b92",
        opacity: 1
      },
      position: {
        x: 0,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#301b92",
        opacity: 1
      },
      position: {
        x: 1,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#301b92",
        opacity: 1
      },
      position: {
        x: 2,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#301b92",
        opacity: 1
      },
      position: {
        x: 3,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#301b92",
        opacity: 1
      },
      position: {
        x: 4,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#301b92",
        opacity: 1
      },
      position: {
        x: 5,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#301b92",
        opacity: 1
      },
      position: {
        x: 6,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#301b92",
        opacity: 1
      },
      position: {
        x: 7,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 0,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#aa47bc",
        opacity: 1
      },
      position: {
        x: 3,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 4,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#aa47bc",
        opacity: 1
      },
      position: {
        x: 5,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 6,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 7,
        y: 0
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#45289f",
        opacity: 1
      },
      position: {
        x: 0,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#45289f",
        opacity: 1
      },
      position: {
        x: 1,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#45289f",
        opacity: 1
      },
      position: {
        x: 2,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#45289f",
        opacity: 1
      },
      position: {
        x: 3,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#45289f",
        opacity: 1
      },
      position: {
        x: 4,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#45289f",
        opacity: 1
      },
      position: {
        x: 5,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#45289f",
        opacity: 1
      },
      position: {
        x: 6,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#45289f",
        opacity: 1
      },
      position: {
        x: 7,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 0,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#b968c7",
        opacity: 1
      },
      position: {
        x: 2,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#273238",
        opacity: 1
      },
      position: {
        x: 3,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#b968c7",
        opacity: 1
      },
      position: {
        x: 4,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#273238",
        opacity: 1
      },
      position: {
        x: 5,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#273238",
        opacity: 1
      },
      position: {
        x: 6,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#273238",
        opacity: 1
      },
      position: {
        x: 7,
        y: 1
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#7c4dff",
        opacity: 1
      },
      position: {
        x: 0,
        y: 2
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#7c4dff",
        opacity: 1
      },
      position: {
        x: 1,
        y: 2
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#7c4dff",
        opacity: 1
      },
      position: {
        x: 2,
        y: 2
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#7c4dff",
        opacity: 1
      },
      position: {
        x: 3,
        y: 2
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#7c4dff",
        opacity: 1
      },
      position: {
        x: 4,
        y: 2
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#7c4dff",
        opacity: 1
      },
      position: {
        x: 5,
        y: 2
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#7c4dff",
        opacity: 1
      },
      position: {
        x: 6,
        y: 2
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#7c4dff",
        opacity: 1
      },
      position: {
        x: 7,
        y: 2
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 0,
        y: 2
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#b968c7",
        opacity: 1
      },
      position: {
        x: 2,
        y: 2
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#273238",
        opacity: 1
      },
      position: {
        x: 3,
        y: 2
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#273238",
        opacity: 1
      },
      position: {
        x: 4,
        y: 2
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#273238",
        opacity: 1
      },
      position: {
        x: 5,
        y: 2
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#273238",
        opacity: 1
      },
      position: {
        x: 6,
        y: 2
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#536dfe",
        opacity: 1
      },
      position: {
        x: 0,
        y: 3
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#536dfe",
        opacity: 1
      },
      position: {
        x: 1,
        y: 3
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#536dfe",
        opacity: 1
      },
      position: {
        x: 2,
        y: 3
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#536dfe",
        opacity: 1
      },
      position: {
        x: 3,
        y: 3
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#536dfe",
        opacity: 1
      },
      position: {
        x: 4,
        y: 3
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#536dfe",
        opacity: 1
      },
      position: {
        x: 5,
        y: 3
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#536dfe",
        opacity: 1
      },
      position: {
        x: 6,
        y: 3
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#536dfe",
        opacity: 1
      },
      position: {
        x: 7,
        y: 3
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 0,
        y: 3
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#b968c7",
        opacity: 1
      },
      position: {
        x: 2,
        y: 3
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 3,
        y: 3
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#f40057",
        opacity: 1
      },
      position: {
        x: 6,
        y: 3
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 7,
        y: 3
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#00b8d4",
        opacity: 1
      },
      position: {
        x: 0,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#00b8d4",
        opacity: 1
      },
      position: {
        x: 1,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#00b8d4",
        opacity: 1
      },
      position: {
        x: 2,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#00b8d4",
        opacity: 1
      },
      position: {
        x: 3,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#00b8d4",
        opacity: 1
      },
      position: {
        x: 4,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#00b8d4",
        opacity: 1
      },
      position: {
        x: 5,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#00b8d4",
        opacity: 1
      },
      position: {
        x: 6,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#00b8d4",
        opacity: 1
      },
      position: {
        x: 7,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 0,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#b968c7",
        opacity: 1
      },
      position: {
        x: 2,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 3,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ff4181",
        opacity: 1
      },
      position: {
        x: 4,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#273238",
        opacity: 1
      },
      position: {
        x: 5,
        y: 4
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#00e5ff",
        opacity: 1
      },
      position: {
        x: 0,
        y: 5
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#00e5ff",
        opacity: 1
      },
      position: {
        x: 1,
        y: 5
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#00e5ff",
        opacity: 1
      },
      position: {
        x: 2,
        y: 5
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#00e5ff",
        opacity: 1
      },
      position: {
        x: 3,
        y: 5
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#00e5ff",
        opacity: 1
      },
      position: {
        x: 4,
        y: 5
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#00e5ff",
        opacity: 1
      },
      position: {
        x: 5,
        y: 5
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#00e5ff",
        opacity: 1
      },
      position: {
        x: 6,
        y: 5
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#00e5ff",
        opacity: 1
      },
      position: {
        x: 7,
        y: 5
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 0,
        y: 5
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ff4181",
        opacity: 1
      },
      position: {
        x: 4,
        y: 5
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 5,
        y: 5
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 6,
        y: 5
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#17ffff",
        opacity: 1
      },
      position: {
        x: 0,
        y: 6
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#17ffff",
        opacity: 1
      },
      position: {
        x: 1,
        y: 6
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#17ffff",
        opacity: 1
      },
      position: {
        x: 2,
        y: 6
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#17ffff",
        opacity: 1
      },
      position: {
        x: 3,
        y: 6
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#17ffff",
        opacity: 1
      },
      position: {
        x: 4,
        y: 6
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#17ffff",
        opacity: 1
      },
      position: {
        x: 5,
        y: 6
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#17ffff",
        opacity: 1
      },
      position: {
        x: 6,
        y: 6
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#17ffff",
        opacity: 1
      },
      position: {
        x: 7,
        y: 6
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 0,
        y: 6
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ff4181",
        opacity: 1
      },
      position: {
        x: 2,
        y: 6
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#273238",
        opacity: 1
      },
      position: {
        x: 3,
        y: 6
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#83ffff",
        opacity: 1
      },
      position: {
        x: 0,
        y: 7
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#83ffff",
        opacity: 1
      },
      position: {
        x: 1,
        y: 7
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#83ffff",
        opacity: 1
      },
      position: {
        x: 2,
        y: 7
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#83ffff",
        opacity: 1
      },
      position: {
        x: 3,
        y: 7
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#83ffff",
        opacity: 1
      },
      position: {
        x: 4,
        y: 7
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#83ffff",
        opacity: 1
      },
      position: {
        x: 5,
        y: 7
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#83ffff",
        opacity: 1
      },
      position: {
        x: 6,
        y: 7
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#83ffff",
        opacity: 1
      },
      position: {
        x: 7,
        y: 7
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#263238",
        opacity: 1
      },
      position: {
        x: 0,
        y: 7
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ff80ab",
        opacity: 1
      },
      position: {
        x: 2,
        y: 7
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ff80ab",
        opacity: 1
      },
      position: {
        x: 3,
        y: 7
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#273238",
        opacity: 1
      },
      position: {
        x: 4,
        y: 7
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#ff80ab",
        opacity: 1
      },
      position: {
        x: 5,
        y: 7
      }
    }, {
      width: 1,
      height: 1,
      material: {
        color: "#273238",
        opacity: 1
      },
      position: {
        x: 6,
        y: 7
      }
    }],
    background: "rgb(225 225 225)",
    animation: ["enableFirstAnimation", "ArrowRight", "ArrowLeft", "ArrowDown", "D"]
  }]
});
exports["default"] = _default;